import {
  BandmanagerRestApiCreateUniformDto
} from "@digitale-menschen/bandmanager-rest-api/src/models/bandmanager-rest-api-create-uniform-dto";

export namespace OnboardingStateActions {

  export class SetUniform {
    static readonly type = '[Onboarding] SetUniform';

    constructor(public uniform: BandmanagerRestApiCreateUniformDto) {
    }
  }

  export class SetUsername {
    static readonly type = '[Onboarding] SetUsername';

    constructor(public username: string) {
    }
  }

  export class SetEmailPassword {
    static readonly type = '[Onboarding] SetEmailPassword';

    constructor(public email: string, public password: string) {
    }
  }

  export class SetBandNameAndCountry {
    static readonly type = '[Onboarding] SetBandAndCountry';

    constructor(public bandName: string, public countryId: string) {
    }
  }

  export class SetLogo {
    static readonly type = '[Onboarding] SetLogo';

    constructor(public logo: Blob) {
    }
  }
}
